import React, { useContext } from "react";
import { GlobalDataContext } from "../context/context";
import TransparentHeader from "../components/global/TransparentHeader";
import BaseLayout from "../components/global/BaseLayout";


function About() {
  const { rpdata } = useContext(GlobalDataContext);
  return (
    <BaseLayout PageName="Applie To Job">
      <div className="md:max-w-full w-full">
        <TransparentHeader
          headertitle="Applie To Job"
          Subheader="Applie To Job"
          bgimg={`${rpdata?.gallery?.[5]}`}
        />


              <div className="p-24">

                  <div class="elfsight-app-68d9c8ef-8890-45a7-8015-0e45e1f3473f" data-elfsight-app-lazy></div>
              </div>

      </div>
    </BaseLayout>
  );
}

export default About;
